import {AppBar, Container, IconButton, makeStyles, Toolbar, Typography} from "@material-ui/core";
import logo from "../../assets/icons/logo.png";
import React, {useContext} from "react";
import { Link } from 'react-router-dom'
import AuthContext from "../auth-context/context";
import firebase from '../../firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#fff',
    color: '#000'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    marginRight: 30,
    fontWeight: 700,
  },
  menuImg: {
    width: '37px',
    height: '37px'
  },
  userWrap: {
    marginLeft: 'auto',
    display: 'flex'
  },
  userName: {
    margin: 0,
    paddingRight: '20px'
  },
  logout: {
    background: 'none',
    border: 'none',
    paddingLeft: '20px',
    borderLeft: '1px solid #000',
    color: '#000',
    textDecoration: 'none'
  }
}));

const Header = () => {

  const classes = useStyles();
  const context = useContext(AuthContext);

  const showUsername = () => {
    if(context.currentUser === undefined) {
      return ''
    }else {
      return typeof context.currentUser === 'string' ? context.currentUser : context.currentUser.username;
    }
  }

  return (
    <AppBar position="static" className={classes.root}>
      <Container maxWidth="xl">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <img src={logo} className={classes.menuImg} alt="logo"/>
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            Мониторинг пастбищ
          </Typography>

          <Link to="/" style={{marginRight: 15}}>Урожайность</Link>
          <Link to="/plants">Растительность</Link>

          <div className={classes.userWrap}>
            <p className={classes.userName}>
              {showUsername()}
            </p>
            <a href="/login" onClick={() => firebase.auth().signOut()} className={classes.logout}>Выйти</a>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
};

export default Header;
