import React from 'react';
import {Link} from 'react-router-dom';
import {Button, makeStyles, Paper, TableBody, TableCell,Table, TableContainer, TableHead, TableRow} from "@material-ui/core";
import firebase from "../../firebase";

const useStyles = makeStyles(() => ({
  table: {
    filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))',
    '& thead th': {
      fontWeight: 700
    }
  },
  tableRow: {
    '&:hover': {
      background: '#F1F6F8'
    }
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#f44336",
    color: "#fff",
    marginLeft: 10,

    '&:hover': {
      background: '#f44336'
    }
  },
}));

const CustomTable = ({data, loadUsers, setLoading}) => {
  const classes = useStyles();

  function remove(id) {
    setLoading(true)

    firebase
      .database()
      .ref('/users')
      .child(id)
      .remove()
      .then(loadUsers)
      .catch(err => console.log('user remove', err))
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >№</TableCell>
            <TableCell align="left">Имя</TableCell>
            <TableCell align="left">Фотография</TableCell>
            <TableCell align="left">Организация</TableCell>
            <TableCell align="left">Должность</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Номер телефона</TableCell>
            <TableCell align="left">Дата регистрации</TableCell>
            {/* <TableCell align="left">Подследняя активность</TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item,idx) => (
            <TableRow key={item.id + idx} className={classes.tableRow}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell align="left">
                {item.username}
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.userPhoto && 
                  <img src={item.userPhoto} alt="user" width="200px" height="200px" />
                }
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.organization}
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.post}
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.email}
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.phone}
              </TableCell>
              <TableCell align="left">
                {item.date}
              </TableCell>
              {/* <TableCell align="left" component="th" scope="row">
              </TableCell> */}
              <TableCell align="left" component="th" scope="row">
                <Button onClick={() => remove(item.id)} className={classes.button}>
                  Удалить
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomTable;
