import React, {useEffect, useState, useContext} from 'react';
import {Box, Button,CircularProgress} from '@material-ui/core';
import {isValidPhoneNumber} from 'react-phone-number-input/input';
import InputMask from 'react-input-mask'
import firebase from "../../firebase";
import AuthContext from '../auth-context/context';
import Input from './input';
import logo from '../../assets/icons/logo.png';
import './login.css';

const Login = props => {

  const [error, setError] = useState(false);
  const [password,setPassword] = useState('');
  const [passwordWrite,setPasswordWrite] = useState(false);
  const [value, setValue] = useState();
  const [loading,setLoading] = useState(false);
  const {isAuth,setAuth} = useContext(AuthContext);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
    });
  },[]);

  useEffect(() => {
    setError(isValidPhoneNumber(value));
  }, [value]);

  useEffect(() => {
    if(isAuth) props.history.push('/');
  },[isAuth,props.history]);

  function onSignInSubmit (e) {
    e.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    setLoading(true);
    firebase.auth().signInWithPhoneNumber(value, appVerifier)
      .then(function (confirmationResult) {
        setLoading(false);
        setPasswordWrite(!passwordWrite);
        window.confirmationResult = confirmationResult;
      }).catch(function (error) {
        console.error(error);
        setLoading(false);
        setError(false);
    });
  }

  const codeAuth = (e) => {
    e.preventDefault();
    setLoading(true);
    window.confirmationResult.confirm(password).then((credential) => {
      setAuth(value);
    }).catch(e => {
      console.error(e)
      setLoading(false)
    })
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" className="login">
      <Box p={4} color="text.primary" className="login__form-wrap">
        <form className="login__form" onSubmit={!passwordWrite ? onSignInSubmit : codeAuth}>
          <div id="recaptcha-container" />
          <img src={logo} alt="logo"/>

          <p className="login__title">Вход в систему</p>

          {passwordWrite ? (
            <Input
              placeholder='Введите код'
              error={error ? "" : "Код не верный"}
              value={password}
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
          ) : (
            <InputMask
              mask="(+\9\96) 999 999 999"
              value={value}
              onChange={e => setValue(e.target.value)}
              placeholder="Введите номер телефона"
            >
              {inputProps => <Input {...inputProps}/>}
            </InputMask>
          )}

          <Box display="flex" className="login__button-wrap">
            <Button className="login__button" variant="contained">Отмена</Button>
            <Button type="submit" disabled={!error} className={`login__button ${error ? '' : 'login__button--disable'}`}
                    variant="contained">
              {!loading ? "Войти" : <CircularProgress size={20} color='#fff'/>}
            </Button>
          </Box>

        </form>
      </Box>
      {/*<div className="login__language">*/}
      {/*  <button className="login__language-button">кыргызча</button>*/}
      {/*  <button className="login__language-button login__language-button--active">русский</button>*/}
      {/*  <button className="login__language-button">english</button>*/}
      {/*</div>*/}
    </Box>
  )
};

export default Login;
