import React, {useEffect, useState } from 'react';
import {
  Container,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import firebase from "../../firebase";
import arrow from "../../assets/icons/back-arrow.png";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  loading: {
    margin: '0 auto'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    },
  }
}))

const containerStyle = {
  width: '100%',
  height: '650px'
};

const getHarvests = () => firebase.database().ref('/harvest').once('value');

function PasturesMap() {
  const classes = useStyles() 
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState()
  const [harvests, setHarvests] = useState([])
  const [markeredHarvests, setMarkeredHarvests] = useState([])
  const [info, setInfo] = useState(null)
  const [infoTooltip, setInfoTooltip] = useState(null)
  const [harvest, setHarvest] = useState(null)
  const [currentValue, setCurrentValue] = useState(null)
  const [kmlLink, setKmlLink] = useState(null)
  const [selectedKmlLink, setSelectedKmlLink] = useState(null)

  useEffect(() => {
    getHarvests().then(harvestsRes => {
      setHarvests(Object.values(harvestsRes.val()))
      setMarkeredHarvests(Object.values(harvestsRes.val()))
      // setInfo(Object.values(harvestsRes.val())[0])
      setKmlLink('data:text/plain;charset=utf-8,' + encodeURIComponent(
        `<?xml version="1.0" encoding="UTF-8"?>
          <kml xmlns="http://www.google.com/earth/kml/2">
          <Document>
      ` + Object.values(harvestsRes.val()).map(harv => {
        return `
          <Placemark>
            <name>${harv.pastureName}</name>
            <description>${harv.pastureName}</description>
            <Point>
              <coordinates>${harv.harvLocation.lon},${harv.harvLocation.lat}</coordinates>
            </Point>
          </Placemark>
      `
      }).join('') + `
        </Document>
        </kml>`
      ))
    })
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAcDAFyQQ6091kgpvPJdn3Gu_XDQBQ8e1I"
  })

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    const infoWindow = new window.google.maps.InfoWindow();

    infoWindow.closeclick = () => setInfo(null)

    setInfoTooltip(infoWindow)

    map.fitBounds(bounds);

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
        },
        
        () => {
          handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false, infoWindow, map.getCenter());
    } 

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    if (!info) {
      return
    }

    infoTooltip.close()

    infoTooltip.setPosition({
      lat: info.harvLocation.lat,
      lng: info.harvLocation.lon
    })
    infoTooltip.setContent(`
      <b>${info.pastureName}</b><br>
      ${info.harvLocation.lat},${info.harvLocation.lon}
    `)

    infoTooltip.open(map)
  }, [info])

  useEffect(() => {
    if (!harvest) {
      setMarkeredHarvests(harvests)
      setInfo(null)
      return
    }

    setInfo(null)
    setMarkeredHarvests([harvest])

    map.setCenter({
      lat: harvest.harvLocation.lat,
      lng: harvest.harvLocation.lon
    })
    
    setSelectedKmlLink('data:text/plain;charset=utf-8,' + encodeURIComponent(
      `<?xml version="1.0" encoding="UTF-8"?>
        <kml xmlns="http://www.google.com/earth/kml/2">
        <Document>
        <Placemark>
          <name>${harvest.pastureName}</name>
          <description>${harvest.pastureName}</description>
          <Point>
            <coordinates>${harvest.harvLocation.lon},${harvest.harvLocation.lat}</coordinates>
          </Point>
        </Placemark>
      </Document>
      </kml>`
    ))
  }, [harvest])

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <Link to='/'>
          <IconButton edge="start" color="inherit" className={classes.arrowBack} aria-label="menu">
            <img src={arrow} alt="back arrow" />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title} style={{ marginRight: 'auto' }}>
          Статистика
        </Typography>


      </Toolbar>

      <Toolbar>
        <div style={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
          <div style={{ marginBottom: 15 }}>
            <Autocomplete
              id="combo-box-demo"
              options={harvests}
              getOptionLabel={(option) => option.pastureName}
              style={{ width: 300 }}
              onChange={((event, value) => {
                setHarvest(value)
                console.log(value);
              })}
              clearOnBlur={(value) => console.log(value)}
              renderInput={(params) => <TextField {...params} label="Поиск пастбищ по названиям" variant="outlined" />}
            />
            <a href={kmlLink} download="sampleAll.kml" style={{margin: '25px 0', display: 'block'}}>Скачать все</a>
            { 
              harvest && 
              <a href={selectedKmlLink} download={`${harvest.pastureName}.kml`} style={{ margin: '25px 0', display: 'block' }}>Скачать {harvest.pastureName}</a>
            }
          </div>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={1}
              center={{
                lat: harvest ? harvest.harvLocation.lat : 42.882004,
                lng: harvest ? harvest.harvLocation.lon : 74.582748
              }}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <Marker
                    position={{
                      lat: harvest ? harvest.harvLocation.lat : 42.882004,
                      lng: harvest ? harvest.harvLocation.lon :74.582748
                    }}
                    key={harvest?.id}
                    onClick={() => {
                      setInfo(harvest)
                    }}
                />
            </GoogleMap>
          ) : null}
        </div>
      </Toolbar>
    </Container>
  )
}

export default React.memo(PasturesMap);
