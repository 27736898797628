import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid, InputBase, makeStyles, MenuItem, Paper, Select, Toolbar, Button, withStyles} from "@material-ui/core";
import {Pie} from "react-chartjs-2";
import noImg from '../../assets/img/no-img.png';
import calendar from "../../assets/icons/calendar.png";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    padding: '8px 16px',
    color: "#fff",
    margin: 10,
    '&:hover': {
      background: "#0f5109",
    }
  },
  paper: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    display: "flex",
    justifyContent: "center",
    borderRadius: '12px',
    paddingLeft: 10
  },
  optionWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    '& *': {
      margin: 0,
      padding: 0,
    }
  },
  optionTitle: {
    marginRight: '10px'
  },
  image: {
    maxWidth: '100%'
  },
  gridWrap: {
    paddingBottom: '40px',
    borderBottom: '0.5px solid #C4C4C4'
  },
  noImgText: {
    color: "#757575",
    fontSize: '15px',
    margin: 0,
  }
}));

const Input = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    background: 'transparent !important',
    fontWeight: 700
  },
}))(InputBase);


const options = {
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        right: 30,
        top: 0
      }
    },
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          try {
            let label = ' ' + data.labels[tooltipItem.index] || '';

            if (label) {
              label += ': ';
            }

            const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
              return accumulator + curValue;
            });
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

            label += Number((value / sum) * 100).toFixed(2) + '%';
            return label;
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      margin: 0,
      padding: 0,
      labels: {
        fontColor: '#333333',
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
      }
    },
  }
;

const VegetableStatisticTable = ({firebaseJson, id, setDataForTable}) => {

  const classes = useStyles();
  const [year, setYear] = React.useState(2020);
  const [image, setImage] = useState();
  let [count,setCount] = useState(0);
  const [canvasData, setCanvasData] = useState({
    labels: ['Голая земля', 'Кустарники', 'Поедаемое расстение', 'Не поедаемое расстение', 'Основа расстений', 'Опад', 'Камень',''],
    datasets: [
      {
        backgroundColor: [
          '#394359',
          '#F4722B',
          '#46AFB9',
          '#A6CB12',
          '#C3BEF0',
          '#449B00',
          '#333333',
          '#fff'
        ],
        hoverBackgroundColor: [
          '#313a4f',
          '#c1703d',
          '#368891',
          '#819d0f',
          '#8d89b8',
          '#3b8302',
          '#242424',
          '#fff'
        ],
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ]
  });

  const parseDataForCanvas = useCallback((jsonFromFirebase) => {
    let emptyCount = 0,
      treeCount = 0,
      bushCount = 0,
      baseCount = 0,
      grassCount = 0,
      windCount = 0,
      stoneCount = 0;

    const data = jsonFromFirebase.map(item => {
      return [
        ...Object.values(item.eastSide),
        ...Object.values(item.northSide),
        ...Object.values(item.westSide),
        ...Object.values(item.southSide)
      ];
    });

    const averageArray = [];
    data.map((dataItem) => {
      return dataItem.map(it => Object.values(it).map(i => {
        const height = parseInt(String(i).replace(/\D/g, ''));
        if (i === 'EMPTY') {
          emptyCount++;
        } else if (i === 'TREE') {
          treeCount++;
        } else if (i === 'BUSH') {
          bushCount++;
        } else if (i === 'BASE') {
          baseCount++;
        } else if (i === 'GRASS') {
          grassCount++;
        } else if (i === 'WIND') {
          windCount++;
        } else if (i === 'STONE') {
          stoneCount++;
        }
        if (height) {
          averageArray.push(height)
        }else {
          averageArray.push(0)
        }
        return null
      }));
    });

    canvasData.labels[canvasData.labels.length - 1] = `Средняя высота ${averageArray.length ? parseInt(averageArray.reduce((a, b) => a + b, 0) / averageArray.length) : 0} см`;
    canvasData.datasets[0].data = [emptyCount, treeCount, bushCount, baseCount, grassCount, windCount, stoneCount, 0];
    const datasetCopy = [...canvasData.datasets];

    if (setDataForTable) {
      const temp = [...canvasData.datasets[0].data];
      temp.splice(canvasData.datasets[0].data.length - 1, 1);

      setDataForTable(temp.map((item, idx) => ({
        staticField: canvasData.labels[idx],
        staticValue: item
      })));
    }

    setCanvasData(Object.assign({}, canvasData, {
      datasets: datasetCopy
    }));
  },[canvasData,setDataForTable]);

  useEffect(() => {
    const jsonFromFirebase = firebaseJson.filter(item => item.userId === id);
    jsonFromFirebase.some((item) => {
      if (item.plantPhoto !== '' && image !== '') {
        setImage(item.plantPhoto);
        return true;
      }
      return null
    });
    if(count < 2) {
      count++;
      setCount(count);
      parseDataForCanvas(jsonFromFirebase);
    }
  }, [firebaseJson, id, image,parseDataForCanvas,count]);

  const optionsForSelect = useMemo(() => {

    let years = new Set(
      firebaseJson.map((item) => {
        return (item.date.replace(/[^a-z\d\s]+/gi, '').split(' ').filter(item => +item >= 2020).join(''))
      })
    );

    years = new Set([...years].map(item => {
      const stringItem = String(item);
      if(stringItem.length > 4) {
        return Number(stringItem.slice(-4));
      }
      return Number(item);
    }));

    return [...years].map(item => (
      <MenuItem key={item} value={item}>{item}</MenuItem>
    ))
  }, [firebaseJson]);

  const handleChange = (event) => {
    const jsonFromFirebase = firebaseJson.filter((item) => {
      return (item.userId === id) && +event.target.value === +item.date.replace(/[^a-z\d\s]+/gi, '').split(' ').filter(item => +item >= 2020).join('')
    });
    if (jsonFromFirebase.length > 0) {
      setYear(event.target.value);
      parseDataForCanvas(jsonFromFirebase)
    }
  };

  // useEffect(() => {
  //   const jsonFromFirebase = firebaseJson.filter((item) => {
  //     return (item.userId === id) && +year === +item.date.replace(/[^a-z\d\s]+/gi, '').split(' ').filter(item => +item >= 2020).join('')
  //   });
  //
  // }, [year]);

  return (
    <>
      <Toolbar>
        <div className={classes.optionWrap}>
          <img src={calendar} style={{margin: '0 20px'}} alt={'calendar icon'}/>
          <p className={classes.optionTitle}>Период</p>
          <Select
            value={year}
            className={classes.margin}
            onChange={handleChange}
            input={<Input/>}
          >
            {optionsForSelect}
          </Select>
        </div>
      </Toolbar>
      <Toolbar className={classes.gridWrap}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box p={2}>
              <Paper className={classes.paper}
                     style={{height: '100%', minHeight: "350px", display: 'flex', alignItems: 'center'}}>
                <Pie options={options} data={canvasData} width={100} />
              </Paper>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box p={2}>
              <Paper p={2} className={classes.paper} style={{
                height: '100%',
                minHeight: "350px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
                {image ? (
                  <>
                    <img onError={e => {
                      e.target.src = noImg
                    }} style={{ maxWidth: '100%' }} src={"data:image/png; base64, " + image} alt="Vegetable" />

                    <a href={`data:image/png; base64, ${image}`} download style={{ textDecoration: 'none' }}>
                      <Button className={classes.button}>
                        Скачать
                      </Button>
                    </a>
                  </>
                ) : (
                  <>
                    <img src={noImg} alt="No img"/>
                    <p className={classes.noImgText}>Фотография отсутствует</p>
                  </>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </>
  )
}

export default VegetableStatisticTable;
