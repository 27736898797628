import React, {useEffect, useState} from 'react';
import {
  Container,
  IconButton,
  Toolbar,
  Typography,
  MenuItem,
  Button,
  Menu,
  ListItemIcon,
  Divider,
  ListItemText,
  makeStyles,
  withStyles, CircularProgress,
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import arrow from "../../assets/icons/back-arrow.png";
import vegetation from '../../assets/icons/vegetation.png';
import corn from '../../assets/icons/corn.png';
import firebase from "../../firebase";
import PostureTable from './table';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    }
  },
  tableWrap: {
    marginTop: '20px'
  },
  select: {
    marginLeft: 'auto'
  },
  icon: {
    minWidth: '30px'
  },
  liFirst: {
    marginBottom: '5px'
  },
  liLast: {
    marginTop: '5px'
  },
  ul: {
    '& a': {
      textDecoration: 'none',
      color: '#000'
    }
  },
  loading: {
    display: "flex",
    width: "100%",
    justifyContent: 'center'
  }
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {},
}))(MenuItem);

const PastureMore = ({match}) => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState([]);
  const [user,setUser] = useState();
  const [loading, setLoading] = useState(true)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPlants = () => firebase.database().ref(`/plants`).once('value');
  const getUsers = () => firebase.database().ref('/users').once('value');

  useEffect(() => {
    getPlants()
      .then(getPlantsRes => {
        getUsers().then(usersRes => {
          const mergedPlantsWithUsers = Object.entries(getPlantsRes.val()).map(([mainId, item]) => {
            for (let key of Object.keys(usersRes.val())) {
              if (item.userId === key && !item.user) {
                item.user = usersRes.val()[key];
                item.mainId = mainId
                return item;
              }
            }
            return null
          });

          setData(Object.values(mergedPlantsWithUsers).filter(item => item && (item.userId === match.params.id && item.plotName === match.params.plotName)));
          setLoading(false)
        })
      });

    firebase.database().ref(`/users/${match.params.id}`).once('value')
      .then(user => {
        setUser(user.val());
      })
  }, [match.params.id,match.params.plotName])


  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <Link to='/'>
          <IconButton edge="start" color="inherit" className={classes.arrowBack} aria-label="menu">
            <img src={arrow} alt="back arrow"/>
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Пастбище {match.params.pastureName} | {match.params.plotName} | <span style={{textTransform: 'capitalize'}}>{user && user.username}</span>
        </Typography>

        <>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClick}
          >
            Отчетность
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={classes.ul}
            onClose={handleClose}
          >
            <Link to={`/vegetable-statistic/${match.params.id}/${match.params.plotName}/${match.params.pastureName}`}>
              <StyledMenuItem className={classes.liFirst}>
                <ListItemIcon className={classes.icon}>
                  <img src={vegetation} alt="vegetation"/>
                </ListItemIcon>
                <ListItemText primary="Растительный покров"/>
              </StyledMenuItem>
            </Link>

            <Divider />

            <Link to={`/yield-statistic/${match.params.id}/${match.params.plotName}/${match.params.pastureName}/${user ? user.username : null}`}>
              <StyledMenuItem className={classes.liLast}>
                <ListItemIcon className={classes.icon}>
                  <img src={corn} alt="corn icon"/>
                </ListItemIcon>
                <ListItemText primary="Урожайность"/>
              </StyledMenuItem>
            </Link>
          </StyledMenu>
        </>

      </Toolbar>
      <Toolbar className={classes.tableWrap}>
        {data && !loading ? <PostureTable data={data}/> : <div className={classes.loading}><CircularProgress/></div>}
      </Toolbar>
    </Container>
  )
};

export default PastureMore;
