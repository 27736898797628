import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyCNSlRlhky4qnXpKrfc5HmOhU3G6VV3eno",
  authDomain: "forestry-75f80.firebaseapp.com",
  databaseURL: "https://forestry-75f80.firebaseio.com",
  projectId: "forestry-75f80",
  storageBucket: "forestry-75f80.appspot.com",
  messagingSenderId: "141435710594",
  appId: "1:141435710594:web:c529b49379d2025cf9589b",
  measurementId: "G-SV8NM9HZ3Z"
};
firebase.initializeApp(firebaseConfig);
export default firebase;