import {createContext} from "react";


const AuthContext = createContext({
  isAuth: false,
  setAuth: () => {},
  currentUser: null,
  setCurrentUser: () => {}
});

export default AuthContext;
