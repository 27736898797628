import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import AuthContext from "./context";
import firebase from '../../firebase';

const AuthContextWrapper = ({children}) => {

  const [isAuth,setIsAuth] = useState(false);
  const [currentUser,setCurrentUser] = useState();
  const [phoneNumber,setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true)

  const changeAuth = (phone) => {
    setPhoneNumber(phone);
    setIsAuth(!isAuth);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(userAuth => {
      if (userAuth) {
        console.log(userAuth)
        setIsAuth(userAuth);

        if (isAuth) {
          firebase.database().ref('/users').once('value')
            .then(res => {
              Object.values(res.val()).some(item => {
                if (item.phone.trim() === phoneNumber.replace(/[- )(]/g, '')) {
                  setCurrentUser(item);
                  return true;
                } else {
                  setCurrentUser("Аноним");
                }
                return null
              })

              setLoading(false)
            })
        }
      }

      setLoading(false)
    });

  }, [isAuth, phoneNumber])

  if (loading) return null

  if(!isAuth && (window.location.pathname !== '/login')) return <Redirect to="/login" />

  return (
    <AuthContext.Provider value={{
      isAuth,
      setAuth: changeAuth,
      currentUser,
      setCurrentUser
    }}>
      {children}
    </AuthContext.Provider>
  )
};

export default AuthContextWrapper;
