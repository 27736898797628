import React, {useEffect, useState } from 'react'
import {
  Container,
  IconButton,
  Toolbar,
  Button,
  Card,
  TextField,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import firebase from "../../firebase";
import arrow from "../../assets/icons/back-arrow.png";
import { setSeconds } from 'date-fns';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  loading: {
    margin: '0 auto'
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    color: "#fff",
    marginLeft: 10,
    '&:hover': {
      background: "#0f5109",
    },
  },
  form: {
    maxWidth: 600,

    '& > div': {
      margin: 10,
    }
  }
}))

export default function Users({ history }) {
  const classes = useStyles() 
  const [fields, setFields] = useState({
    username: '',
    organization: '',
    post: '',
    email: '',
    phone: '',
    date: '',
    lang: '',
    userPhoto: null
  })

  function save() {
    const data = {...fields}
    const reader = new FileReader()

    function send() {
      firebase.database().ref('/users').push().set(data)
        .then(() => history.push('/users'))
        .catch(err => console.log('Users add', err))
    }

    if (fields.userPhoto) {
      reader.onloadend = () => {
        data.userPhoto = reader.result
        send()
      }

      reader.readAsDataURL(fields.userPhoto)
    } else {
      send()
    }
  }

  function handleChange(event) {
    if (event.target.name !== 'userPhoto')
      setFields({ ...fields, [event.target.name]: event.target.value })

    else {
      setFields({...fields, 'userPhoto': event.target.files[0]})
    }
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <Link to='/'>
          <IconButton edge="start" color="inherit" className={classes.arrowBack} aria-label="menu">
            <img src={arrow} alt="back arrow" />
          </IconButton>
        </Link>
      </Toolbar>

      <Card>
        <CardContent>
          <form className={classes.form}>
            <TextField onChange={handleChange} variant="outlined" label="Имя" name="username" />
            <TextField onChange={handleChange} variant="outlined" label="Организация" name="organization" />
            <TextField onChange={handleChange} variant="outlined" label="Должность" name="post" />
            <TextField onChange={handleChange} variant="outlined" label="Email" name="email" />
            <TextField onChange={handleChange} variant="outlined" label="Номер телефона" name="phone" />
            <TextField onChange={handleChange} variant="outlined" label="Дата" name="date" />
            <TextField onChange={handleChange} variant="outlined" label="Язык" name="lang" />
            <TextField onChange={handleChange} variant="outlined" label="Фото" name="userPhoto" type="file" />
            <Button onClick={save} className={classes.button}>Сохранить</Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}