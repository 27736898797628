import React, {useEffect, useState } from 'react'
import {
  Container,
  IconButton,
  Toolbar,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import Table from './table';
import firebase from "../../firebase";
import arrow from "../../assets/icons/back-arrow.png";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  loading: {
    margin: '0 auto'
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    },
  }
}))

export default function Users() {
  const classes = useStyles() 
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  const getUsers = () => firebase.database().ref('/users').once('value');

  function loadUsers() {
    getUsers().then(usersRes => {
      setUsers(Object.keys(usersRes.val()).map(id => {
        return { ...usersRes.val()[id], id }
      }))
      setLoading(false)
    })
  }

  useEffect(() => {
    getUsers().then(usersRes => {
      setUsers(Object.keys(usersRes.val()).map(id => {
        return { ...usersRes.val()[id], id }
      }))
      setLoading(false)
    })
  }, [])
  
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <Link to='/' style={{ marginRight: 'auto' }}>
          <IconButton edge="start" color="inherit" className={classes.arrowBack} aria-label="menu">
            <img src={arrow} alt="back arrow" />
          </IconButton>
        </Link>

        <Link to="/users/add" style={{ textDecoration: 'none' }}>
          <Button className={classes.button}>Добавить</Button>
        </Link>
      </Toolbar>

      <Toolbar>
        {users && !loading ? <Table data={users} loadUsers={loadUsers} setLoading={setLoading}/> :
          <CircularProgress className={classes.loading} />}
      </Toolbar>
    </Container>
  )
}