import React, {useEffect, useState} from 'react';
import {
  IconButton,
  Toolbar,
  Container,
  Typography,
  Button,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import firebase from '../../firebase';
import arrow from "../../assets/icons/back-arrow.png";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import VegetableStatisticTable from "./table";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    padding: '8px 16px',
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    }
  },
  paper: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    display: "flex",
    justifyContent: "center",
    borderRadius: '12px'
  },
  optionWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    '& *': {
      margin: 0,
      padding: 0,
    }
  },
  optionTitle: {
    marginRight: '10px'
  },
  image: {
    maxWidth: '100%'
  },
  gridWrap: {
    paddingBottom: '40px',
    borderBottom: '0.5px solid #C4C4C4'
  },
  loading: {
    display: "flex",
    width: "100%",
    justifyContent: 'center'
  },
  none: {
    display: 'none'
  }
}));


const VegetableStatistic = ({history, match}) => {

  const classes = useStyles();
  const [json, setJson] = useState(null);
  const [dataForTable,setDataForTable] = useState([]);

  const getPlants = () => firebase.database().ref(`/plants`).once('value');
  const getUsers = () => firebase.database().ref('/users').once('value');
  const getPlantTypes = () => firebase.database().ref('/plant_types').once('value')
  const getTreeTypes = () => firebase.database().ref('/tree_types').once('value')

  useEffect(() => {
    getPlants()
      .then(plantsRes => {
        getUsers().then(usersRes => {
          getPlantTypes().then(plantTypesRes => {
            getTreeTypes().then(treeTypesRes => {
              const mergedPlantsWithUsers = Object.values(plantsRes.val()).map(plant => {
                for (let userId of Object.keys(usersRes.val())) {
                  if (plant.userId === userId && !plant.user) {
                    plant.user = usersRes.val()[userId]
                    plant.plantTypes = Object.values(plantTypesRes.val().images).filter(plantType => plant.plants.split(', ').includes(plantType.name_ru))
                    plant.treeTypes = Object.values(treeTypesRes.val().images).filter(treeType => plant.trees.split(', ').includes(treeType.name_ru))

                    return plant
                  }
                }

                return null
              })

              setJson(
                Object.values(mergedPlantsWithUsers)
                  .filter(plant => {
                    return plant.userId === match.params.id &&
                      plant.plotName === match.params.plotName
                  })
              )
            })
          })
        })
      })
  }, []);

  const downloadFile = () => document.getElementById('main-table-button').click();

  useEffect(() => {
    console.log(dataForTable)
  })

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <IconButton onClick={history.goBack} edge="start" color="inherit" className={classes.arrowBack}
                    aria-label="menu">
          <img src={arrow} alt="back arrow"/>
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Растительный покров | {match.params.pastureName} | {match.params.plotName}
        </Typography>

        {dataForTable.length ? (
          <Button onClick={downloadFile} className={classes.button}>
            Скачать
          </Button>
        ) : null}

        <ReactHTMLTableToExcel
          id="main-table-button"
          className={classes.none}
          table="vegetable-statistic"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS"/>

      </Toolbar>
      <table
        id="vegetable-statistic"
        className={classes.none}
      >
        <thead>
        <tr>
          <th>Дата замера</th>
          <th>Область</th>
          <th>Район</th>
          <th>Айыл окмоту</th>
          <th>Навзание пастбища</th>
          <th>Название участка</th>
          <th>Описание участка</th>
          <th>Описание точки</th>
          <th>Пользователь</th>
          <th>Координаты</th>
          <th>Виды растений</th>
          <th>Виды растений (кырг)</th>
          <th>Виды растений (eng)</th>
          <th>Древесно-кустарник</th>
          <th>Древесно-кустарник (кырг)</th>
          <th>Древесно-кустарник (eng)</th>
          <th>Текстура почвы</th>
          <th>Степень каменистости в %</th>
          <th>Цвет верхнего слоя почвы</th>
          <th>Промоины</th>
          <th>Пьедесталы</th>
          <th>Скотные тропинки</th>
          <th>Голые участки</th>
          <th>Другие виды эрозии</th>
          <th>Голая земля</th>
          <th>Кустарники</th>
          <th>Поедаемое</th>
          <th>Непоедаемое</th>
          <th>Основа растений</th>
          <th>Опад</th>
          <th>Камень</th>
          <th>Общий растительный покров</th>
          <th>Сумма общей открытой поверхности</th>
          <th>Средняя высота растительности</th>
          <th>Организация</th>
        </tr>
        </thead>
        <tbody>
          {json ?
            json.map(plant =>
              <tr key={plant.id}>
                <td>{plant.date}</td>
                <td>{plant.region}</td>
                <td>{plant.district}</td>
                <td>{plant.village}</td>
                <td>{plant.pastureName}</td>
                <td>{plant.plotName}</td>
                <td>{plant.plotDescription}</td>
                <td>{plant.pointDescription}</td>
                <td>{plant.user.username}</td>
                <td>{plant.plantLocation.lat}, {plant.plantLocation.lon}</td>
                <td>{plant.plants}</td>
                <td>{plant.plantTypes.map(plantType => plantType.name_ky).join(', ')}</td>
                <td>{plant.plantTypes.map(plantType => plantType.name_en).join(', ')}</td>
                <td>{plant.trees}</td>
                <td>{plant.treeTypes.map(plantType => plantType.name_ky).join(', ')}</td>
                <td>{plant.treeTypes.map(plantType => plantType.name_en).join(', ')}</td>
                <td>{plant.soilTexture}</td>
                <td></td>
                <td>{plant.soilColor}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{plant.erosionDegree}</td>
                <td>{plant.bareGround}</td>
                <td>{plant.shrubs}</td>
                <td>{plant.eatenPlant}</td>
                <td>{plant.nonEatenPlant}</td>
                <td>{plant.plantBase}</td>
                <td>{plant.litterFall}</td>
                <td>{plant.stone}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{plant.user.organization}</td>
              </tr>) : null}
        </tbody>
      </table>
      {json ? <VegetableStatisticTable setDataForTable={setDataForTable} firebaseJson={json} id={match.params.id}/> :
        <div className={classes.loading}><CircularProgress/></div>}
    </Container>
  )
}

export default VegetableStatistic;
