import React,{ forwardRef } from 'react';
import {TextField} from "@material-ui/core";

const Input = (props,ref) => {

  return (
    <TextField
      {...props}
      error={!!props.error}
      helperText={props.error}
      fullWidth
      inputRef={ref}
      margin="normal"
      className="login__input"
      color="primary"
      required
    />
  )
}

export default forwardRef(Input);
