import React, {useEffect, useState} from 'react';
import {
  IconButton,
  Toolbar,
  Container,
  Typography,
  Button,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import arrow from "../../assets/icons/back-arrow.png";
import YieldTable from "./table";
import firebase from "../../firebase";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    padding: '8px 16px',
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    }
  },
  loading: {
    display: "flex",
    width: "100%",
    justifyContent: 'center'
  },
  none: {
    display: 'none'
  }
}));

const YieldStatistic = ({match,history}) => {

  const classes = useStyles();
  const [json,setJson] = useState();
  const [dataForTable,setDataForTable] = useState(null);

  const getHarvests = () => firebase.database().ref('/harvest').once('value');
  const getUsers = () => firebase.database().ref('/users').once('value');

  useEffect(() => {
   getHarvests()
      .then(harvestsRes => {
        getUsers().then(usersRes => {
          const mergedHarvestsWithUsers = Object.values(harvestsRes.val()).map(harvest => {
            for (let userId of Object.keys(usersRes.val())) {
              if (harvest.userId === userId && !harvest.user) {
                harvest.user = usersRes.val()[userId]
                return harvest
              }
            }

            return null
          })

          setJson(
            Object.values(mergedHarvestsWithUsers)
              .filter(harvest => {
                return harvest && harvest.userId === match.params.id &&
                       harvest.plotName === match.params.plotName
              })
          )
        })
      })
  },[]);

  const downloadFile = () => document.getElementById('main-table-button').click();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Toolbar>
        <IconButton onClick={history.goBack} edge="start" color="inherit" className={classes.arrowBack} aria-label="menu">
          <img src={arrow} alt="back arrow"/>
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Урожайность | {match.params.pastureName} | {match.params.plotName}
        </Typography>

        {dataForTable && (
          <Button onClick={downloadFile} className={classes.button}>
            Скачать
          </Button>
        )}
      </Toolbar>

      <ReactHTMLTableToExcel
        id="main-table-button"
        className={classes.none}
        table="yield-statistic"
        filename="tablexls"
        sheet="tablexls"
        buttonText="Download as XLS"/>

      <table id="yield-statistic" className={classes.none}>
        <thead>
        <tr>
          <th>Дата замера</th>
          <th>Область</th>
          <th>Район</th>
          <th>Айыл окмоту</th>
          <th>название пастбища</th>
          <th>название участка</th>
          <th>Пользователь</th>
          <th>Координаты</th>
          <th>влажная поедаемая масса</th>
          <th>влажная непоедамая масса</th>
          <th>итого влжаная масса</th>
          <th>дата взвешивания сухой массы</th>
          <th>сухая поедаемая масса</th>
          <th>сухая непоедамая масса</th>
          <th>Организация</th>
        </tr>
        </thead>
        <tbody>
        {json && json.map((item,idx) => {
          if(item.userId.trim() === match.params.id &&
            item.pastureName.toLowerCase() === match.params.pastureName.toLowerCase() &&
            item.plotName.toLowerCase() === match.params.plotName.toLowerCase()) {
            return (
              <tr key={idx}>
                <td>{item.date}</td>
                <td>{item.region}</td>
                <td>{item.district}</td>
                <td>{item.village}</td>
                <td>{item.pastureName}</td>
                <td>{item.plotName}</td>
                <td>{match.params.username}</td>
                <td>{item.harvLocation.lat}, {item.harvLocation.lon}</td>
                <td>{item.sumDryBiomass.eated}</td>
                <td>{item.sumDryBiomass.nonEated}</td>
                <td>{item.sumDryBiomass.sum}</td>
                <td></td>
                <td>{item.sumWetBiomass.eated}</td>
                <td>{item.sumWetBiomass.nonEated}</td>
                <td>{item.user.organization}</td>
              </tr>
            )
          }
          return null
        })}
        </tbody>
      </table>
      {json ? <YieldTable setDataForTable={setDataForTable} firebaseJson={json} id={match.params.id}/> : <div className={classes.loading}><CircularProgress/></div>}
    </Container>
  )
}

export default YieldStatistic;
