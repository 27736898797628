import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from "./components/login";
import VegetableStatistic from "./components/vegetable-statistic";
import AuthContextWrapper from "./components/auth-context";
import MainTable from "./components/main-table";
import MainTable2 from "./components/main-table2";
import PastureMore from './components/second-table';
import PastureMore2 from './components/second-table2';
import YieldStatistic from "./components/yield-statistics";
import Users from './components/users';
import UsersAdd from './components/users/add';
import PasturesMap from './components/map';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthContextWrapper>
          <Route path="/login" exact component={Login}/>
          <Route path="/" exact component={MainTable}/>
          <Route path="/plants" exact component={MainTable2}/>
          <Route path="/vegetable" exact component={VegetableStatistic}/>
          <Route path="/pasture/:id/:plotName/:pastureName" exact component={PastureMore}/>
          <Route path="/plants/:id/:plotName/:pastureName" exact component={PastureMore2}/>
          <Route path="/vegetable-statistic/:id/:plotName/:pastureName" exact component={VegetableStatistic}/>
          <Route path="/yield-statistic/:id/:plotName/:pastureName/:username" exact component={YieldStatistic}/>
          <Route path="/users" exact component={Users} /> 
          <Route path="/users/add" exact component={UsersAdd} /> 
          <Route path="/map" exact component={PasturesMap} />
        </AuthContextWrapper>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
