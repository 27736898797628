import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import {
  Toolbar,
  Typography,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
  Button,
  CircularProgress,
  InputLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from "./header";
import CustomTable from './table';
import firebase from "../../firebase";
import tree from '../../assets/icons/tree.png';
import './main-table.css'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ruLocale from "date-fns/locale/ru";
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(() => ({
  title: {
    marginLeft: "20px",
    display: 'inline-block',
    flexGrow: 0,
    fontWeight: 700,
    marginRight: '30px'
  },
  containerMarginBottom: {
    // margin: "20px 0"
  },
  margin: {
    marginTop: 0,
  },
  optionWrap: {display: 'flex', alignItems: 'baseline', marginRight: '30px'},
  optionTitle: {marginRight: '10px'},
  searchIcon: {color: "#C4C4C4"},
  searchInput: {
    maxWidth: 300,
    height: "36px",
    width: '100%',
    margin: '0',
    '& > div': {
      height: "36px",
      borderRadius: '50px',
      background: '#fff',
    }
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    color: "#fff",
    '&:hover': {
      background: "#0f5109",
    }
  },
  loading: {
    margin: '0 auto'
  },
  none: {
    display: 'none'
  },
  filters: {
    display: 'flex'
  }
}));


const MainTable = () => {

  const classes = useStyles();
  const [loading, setLoading] = useState(true)

  const [region, setRegion] = useState(null)
  const [district, setDistrict] = useState(null)
  const [village, setVillage] = useState(null)

  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const [villages, setVillages] = useState([])

  const getRegions = () => firebase.database().ref('/regions').once('value');
  const getDistricts = () => firebase.database().ref('/districts').once('value');
  const getVillages = () => firebase.database().ref('/villages').once('value');

  useEffect(() => {
    getRegions().then(regionsRes => {
      setRegions(regionsRes.val())
    })
  }, [])

  useEffect(() => {
    if (!region && districts.length) {
      setDistricts([])
      setDistrict(null)
      return
    }

    if (!region) return

    setDistrict(null)

    getDistricts().then(districtsRes => {
      const districts = districtsRes.val().filter(district => district.regionId === region.id)
      setDistricts(districts)
    })
  }, [region])

  useEffect(() => {
    if (!district && villages.length) {
      setVillages([])
      setVillage(null)
      return
    }

    if (!district) return

    setVillage(null)

    getVillages().then(villagesRes => {
      const villages = villagesRes.val().filter(village => village.districtId === district.id)
      setVillages(villages)
    })
  }, [district])



  const [pastures, setPastures] = useState([]);
  const [plants, setPlants] = useState([]);
  const [parsedPasturesData, setPastureData] = useState([]);
  const [date, setDate] = useState({
    first: new Date(2020, 7, 1),
    last: new Date()
  });

  const parseDate = (date) => {
    const dateNumber = date.replace(/-/g, '');
    const year = dateNumber.substr(dateNumber.length - 4);
    const month = dateNumber.substr(dateNumber.length - 6).substr(0, 2);
    const day = dateNumber.substr(0, 2);
    return [+day, +month, +year]
  }


  const getPastures = () => firebase.database().ref('/harvest').once('value');
  const getUsers = () => firebase.database().ref('/users').once('value');

  useEffect(() => {
    getPastures()
      .then(snapshot => {
        getUsers()
          .then(usersObject => {
            const parsedPastures = Object.values(snapshot.val()).map((item) => {
              for (let key of Object.keys(usersObject.val())) {
                if (item.userId === key && !item.user) {
                  item.user = usersObject.val()[key];
                  item.date = parseDate(item.date);

                  return item
                }
              }

              return null
            });

            console.log(parsedPastures)

            const temp = parsedPastures
              .filter(item => item)
              .slice()
              .sort((firstItem, secondItem) => {
                const [fDay, fMonth, fYear] = firstItem.date
                const [sDay, sMonth, sYear] = secondItem.date

                const fDate = new Date([fMonth, fDay, fYear]).getTime()
                const sDate = new Date([sMonth, sDay, sYear]).getTime()

                return fDate - sDate === 0 ? 0 : fDate - sDate > 0 ? -1 : 1
              })

            setPastureData(temp);
            setPastures(temp);
            setLoading(false)
          })
      })
  }, []);

  function applyRegionFilters() {
    setLoading(true)

    getPastures()
      .then(snapshot => {
        getUsers()
          .then(usersObject => {
            let filterFn

            if (village) filterFn = item => item.village === village.name
            else if (district) filterFn = item => item.district === district.name
            else if (region) filterFn = item => item.region === region.name
            else filterFn = () => true

            const pastures = Object.values(snapshot.val())
              .filter(filterFn)
              .map((item) => {
                for (let key of Object.keys(usersObject.val())) {
                  if (item.userId === key && !item.user) {
                    item.user = usersObject.val()[key];
                    item.date = parseDate(item.date);

                    return item
                  }
                }

                return null
              })
              .filter(item => item)
              .slice()
              .sort((firstItem, secondItem) => {
                const [fDay, fMonth, fYear] = firstItem.date
                const [sDay, sMonth, sYear] = secondItem.date

                const fDate = new Date([fMonth, fDay, fYear]).getTime()
                const sDate = new Date([sMonth, sDay, sYear]).getTime()

                return fDate - sDate === 0 ? 0 : fDate - sDate > 0 ? -1 : 1
              })


            setPastureData(pastures);
            setPastures(pastures);
            setLoading(false)
          })
      })
  }

  // useEffect(() => {
  //   const parsedPastures = parsedPasturesData.filter((item) => {
  //     const pasture = +item.date.replace(/[^a-z\d\s]+/gi, '').split(' ').filter(item => +item >= 2020).join('');
  //     return (((+firstYear >= pasture && +firstYear < +lastYear) || (+firstYear === +lastYear)) && +lastYear <= pasture) && item;
  //   });
  //   setPastures(parsedPastures);
  // }, [firstYear, lastYear, parsedPasturesData]);

  // useEffect(() => {
  //   if(plants.length === 0 && pastures.length) {
  //     getPlants()
  //       .then(res => {
  //         const parsedPlants = Object.values(res.val()).map(item => {
  //           for(let it of pastures) {
  //             if(item.userId.trim() === it.userId.trim()) {
  //               item.pasture = it;
  //               return item;
  //             }
  //           }
  //         })
  //         setPlants(parsedPlants.filter(c => c));
  //       })
  //   }
  // },[]);

  const filterList = e => {
    const {value} = e.target;

    let queryStrings = value.split(',');

    const temp = parsedPasturesData.filter(item => {

      const {pastureName, plotName, user: {username}} = item;

      if (queryStrings[0] && queryStrings[1] && queryStrings[2]) {

        if (pastureName.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (plotName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[1].toLowerCase())) &&
          (plotName.toLowerCase().includes(queryStrings[2].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[2].toLowerCase()))) {
          return item;
        } else if (plotName.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (pastureName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[1].toLowerCase())) &&
          (pastureName.toLowerCase().includes(queryStrings[2].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[2].toLowerCase()))) {
          return item;
        } else if (username.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (pastureName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            plotName.toLowerCase().includes(queryStrings[1].toLowerCase())) &&
          (pastureName.toLowerCase().includes(queryStrings[2].toLowerCase()) ||
            plotName.toLowerCase().includes(queryStrings[2].toLowerCase()))) {
          return item;
        }

      } else if (queryStrings[0] && queryStrings[1]) {

        if (pastureName.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (plotName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[1].toLowerCase()))) {
          return item;
        } else if (plotName.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (pastureName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            username.toLowerCase().includes(queryStrings[1].toLowerCase()))) {
          return item;
        } else if (username.toLowerCase().includes(queryStrings[0].toLowerCase()) &&
          (pastureName.toLowerCase().includes(queryStrings[1].toLowerCase()) ||
            plotName.toLowerCase().includes(queryStrings[1].toLowerCase()))) {
          return item;
        }

      } else if (queryStrings[0]) {
        if (pastureName.toLowerCase().includes(queryStrings[0].toLowerCase())) {
          return item;
        } else if (plotName.toLowerCase().includes(queryStrings[0].toLowerCase())) {
          return item;
        } else if (username.toLowerCase().includes(queryStrings[0].toLowerCase())) {
          return item
        }
      } else {
        return parsedPasturesData;
      }
      return null
    });
    setPastures(temp);
  };
  const parseDataForStatic = (dataForParse) => {
    let emptyCount = 0,
      treeCount = 0,
      bushCount = 0,
      baseCount = 0,
      grassCount = 0,
      windCount = 0,
      stoneCount = 0;


    const parsedData = [
      ...Object.values(dataForParse.eastSide),
      ...Object.values(dataForParse.northSide),
      ...Object.values(dataForParse.westSide),
      ...Object.values(dataForParse.southSide)
    ]

    const averageArray = [];
    parsedData.map((dataItem) => {
      return Object.values(dataItem).map(item => {
        const height = parseInt(String(item).replace(/\D/g, ''));
        if (item === 'EMPTY') {
          emptyCount++;
        } else if (item === 'TREE') {
          treeCount++;
        } else if (item === 'BUSH') {
          bushCount++;
        } else if (item === 'BASE') {
          baseCount++;
        } else if (item === 'GRASS') {
          grassCount++;
        } else if (item === 'WIND') {
          windCount++;
        } else if (item === 'STONE') {
          stoneCount++;
        } else if (height) {
          averageArray.push(height)
        } else {
          averageArray.push(0)
        }
        return null;
      })
    });
    const dataArray = [emptyCount, treeCount, bushCount, baseCount, grassCount, windCount, stoneCount];
    const sum = dataArray.reduce((a, b) => a + b);

    return (
      <>
        {dataArray.map((item, idx) => (
          <td key={idx}>{Number((item / sum) * 100).toFixed(2)}%</td>
        ))}
        <td>{dataForParse.pasture.sumDryBiomass.sum + dataForParse.pasture.sumWetBiomass.sum}</td>
        <td>{windCount + windCount + stoneCount}</td>
        <td>{`Средняя высота ${Number(averageArray.reduce((a, b) => a + b) / averageArray.length).toFixed(0)}см`}</td>
        <td>{Math.max(...averageArray)}см</td>
        <td>{Math.min(...averageArray)}см</td>
      </>
    )

  };

  useEffect(() => {
    const parsedData = parsedPasturesData.filter((item) => {
      const newDate = item.date;
      const firstDate = new Date(date.first);
      const lastDate = new Date(date.last);
      const firstDateArr = [+firstDate.getDate(), firstDate.getMonth() + 1, +firstDate.getFullYear()];
      const lastDateArr = [+lastDate.getDate(), lastDate.getMonth() + 1, +lastDate.getFullYear()];
      if (
        (
          newDate[0] >= firstDateArr[0] &&
          newDate[1] >= firstDateArr[1] &&
          newDate[2] >= firstDateArr[2]
        ) &&
        (
          newDate[0] <= lastDateArr[0] &&
          newDate[1] <= lastDateArr[1] &&
          newDate[2] <= lastDateArr[2]
        )
      ) {
        return item;
      }

      return null

    })

    if (parsedData.length) setPastures(parsedData)
  }, [date, parsedPasturesData]);

  const getPlants = () => firebase.database().ref('/plants').once('value');
  const getPlantTypes = () => firebase.database().ref('/plant_types').once('value')
  const getTreeTypes = () => firebase.database().ref('/tree_types').once('value')

  const downloadFile = () => {
    if (plants.length === 0) {
      getPlants()
        .then(res => {
          getPlantTypes().then(plantTypesRes => {
            getTreeTypes().then(treeTypesRes => {
              const parsedPlants = Object.values(res.val()).map((item) => {
                for (let it of pastures) {
                  if (item.userId.trim() === it.userId.trim()) {
                    item.pasture = it;
                    item.plantTypes = Object.values(plantTypesRes.val().images).filter(plantType => item.plants.split(', ').includes(plantType.name_ru))
                    item.treeTypes = Object.values(treeTypesRes.val().images).filter(treeType => item.trees.split(', ').includes(treeType.name_ru))

                    return item;
                  }
                }
                return null
              })
              setPlants(parsedPlants.filter(c => c));
            })
          })
        })
    } else {
      document.getElementById('main-table-button').click();
    }
  }

  useEffect(() => {
    if (plants.length > 0) {
      document.getElementById('main-table-button').click();
    }
  }, [plants])

  return (
    <>
      <Header/>
      <Container className={classes.containerMarginBottom} maxWidth="xl">
        <Toolbar>
          <img src={tree} alt="tree"/>

          <Typography variant="h5" className={classes.title}>
              Статистика
          </Typography>

           <div>
              {/* <Link to="/users" style={{ marginRight: 15 }}>Пользователи</Link> */}
              <Link to="/map" style={{ marginRight: 'auto' }}>Карта</Link>
            </div>

          {/*<div className={classes.optionWrap}>*/}
          {/*  <p className={classes.optionTitle}>Измерения</p>*/}
          {/*  <Select*/}
          {/*    value={tableFilter}*/}
          {/*    className={classes.margin}*/}
          {/*    onChange={e => setTableFilter(e.target.value)}*/}
          {/*    input={<Input/>}*/}
          {/*  >*/}
          {/*    <MenuItem value={"Расстительный покров"}>Расстительный покров</MenuItem>*/}
          {/*    <MenuItem value={"Урожайность"}>Урожайность</MenuItem>*/}
          {/*  </Select>*/}
          {/*</div>*/}

        </Toolbar>

        <Toolbar style={{display: 'flex', flexFlow: 'column', alignItems: 'flex-start'}}>
          <div style={{marginBottom: 20 }}>

          <TextField
            type="search"
            variant="outlined"
            margin="normal"
            placeholder="Поиск"
            className={classes.searchInput}
            onChange={filterList}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{width: '50px', display: 'flex', justifyContent: 'center'}} position="start">
                  <span className={`material-icons ${classes.searchIcon}`}>
                    search
                  </span>
                </InputAdornment>
              )
            }}/> 
          </div>

          <div style={{display: 'flex', flexFlow: 'row', aliginItems: 'center', marginBottom: 20}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <InputLabel style={{margin: '0 10px', display: 'flex', alignItems: 'center'}}>Период с</InputLabel>
            <DatePicker
              style={{margin: 0}}
              disableToolbar
              variant="inline"
              autoOk
              format="dd/MM/yyyy"
              margin="normal"
              value={date.first}
              onChange={e => setDate({
                ...date,
                first: e,
              })}
            />
            <InputLabel style={{margin: '0 10px', display: 'flex', alignItems: 'center'}}>по</InputLabel>
            <DatePicker
              style={{margin: 0}}
              disableToolbar
              variant="inline"
              autoOk
              format="dd/MM/yyyy"
              margin="normal"
              value={date.last}
              onChange={e => setDate({
                ...date,
                last: e
              })}
            />
            <Button onClick={() => setPastures(parsedPasturesData)} className={classes.button}
                    style={{marginRight: '10px', marginLeft: '10px'}} variant="contained">
              Сбросить фильтр
            </Button>
          </MuiPickersUtilsProvider>

          {/*{years.length ? (*/}
          {/*  <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>*/}
          {/*    <img src={calendar} style={{margin: '0 20px'}} alt={'calendar icon'}/>*/}

          {/*<div className={classes.optionWrap} style={{marginRight: '10px',alignItems: 'center'}}>*/}
          {/*  <p className={classes.optionTitle}>Год</p>*/}
          {/*  <Select*/}
          {/*    value={firstYear}*/}
          {/*    className={classes.margin}*/}
          {/*    onChange={e => setFirstYear(e.target.value)}*/}
          {/*    input={<Input/>}*/}
          {/*  >*/}
          {/*    {yearsButton}*/}
          {/*  </Select>*/}
          {/*</div>*/}

          {/*    <div className={classes.optionWrap}>*/}
          {/*      <Select*/}
          {/*        value={lastYear}*/}
          {/*        className={classes.margin}*/}
          {/*        onChange={e => setLastYear(e.target.value)}*/}
          {/*        input={<Input/>}*/}
          {/*      >*/}
          {/*        {yearsButton}*/}
          {/*      </Select>*/}
          {/*    </div>*/}
          {/*  </MuiPickersUtilsProvider>*/}
          {/*) : null}*/}

          <ReactHTMLTableToExcel
            id="main-table-button"
            className={classes.none}
            table="main-table"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download as XLS"/>

          {pastures.length > 0 && <Button className={classes.button} onClick={downloadFile} variant="contained">
            Скачать
          </Button>}


          </div>
        </Toolbar>

        <Toolbar style={{marginBottom: 20}}>
          <div className={classes.filters}>
            <Autocomplete
              options={regions}
              getOptionLabel={(option) => option.name}
              style={{ width: 250, marginRight: 10, background: '#fff' }}
              onChange={(event, value) => setRegion(value)}
              renderInput={(params) => <TextField {...params} label="Область" variant="outlined" />}
            />

            <Autocomplete
              options={districts}
              getOptionLabel={(option) => option.name}
              value={district}
              onChange={(event, value) => setDistrict(value)}
              style={{ width: 250, marginRight: 10, background: '#fff' }}
              renderInput={(params) => <TextField {...params} label="Район" variant="outlined" />}
            />

            <Autocomplete
              options={villages}
              getOptionLabel={(option) => option.name}
              value={village}
              onChange={(event, value) => setVillage(value)}
              style={{ width: 250, background: '#fff' }}
              renderInput={(params) => <TextField {...params} label="Айыл аймак" variant="outlined" />}
            />

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                className={classes.button}
                style={{ marginLeft: 10 }}
                onClick={applyRegionFilters}
              >
                Применить
              </Button>
            </div>
          </div>
        </Toolbar>

        <Toolbar>
            Урожайность
        </Toolbar>

      </Container>
      <table className={classes.none} id={"main-table"}>
        <thead>
        <tr>
          <th>Дата</th>
          <th>Область</th>
          <th>Район</th>
          <th>Аильный аймак</th>
          <th>Название организации</th>
          <th>Пользователь</th>
          <th>Название пастбища</th>
          <th>Название участка</th>
          <th>Описание участка</th>
          <th>Описание точки</th>
          <th>N</th>
          <th>E</th>
          <th>Высота</th>
          <th>Виды растений</th>
          <th>Виды растений (кырг)</th>
          <th>Виды растений (eng)</th>
          <th>древесно-кустарниковые</th>
          <th>древесно-кустарниковые (кырг)</th>
          <th>древесно-кустарниковые (eng)</th>
          <th>текстура почвы</th>
          <th>степень каменистости</th>
          <th>цвет верхнего слоя почвы</th>
          <th>промоины</th>
          <th>педьесталы</th>
          <th>скотные тропинки</th>
          <th>голые участки</th>
          <th>другое</th>
          <th>выпас скота</th>
          <th>голая земля</th>
          <th>кустарники</th>
          <th>поедаемое</th>
          <th>непоедаемое</th>
          <th>основа растений</th>
          <th>опад</th>
          <th>камень</th>
          <th>общий растительный покров (поедаем+непоедамое)</th>
          <th>Сумма общей открытой поверхности (голая земля+опад+камень)</th>
          <th>средняя высота растительности (cm)</th>
          <th>максимальная высота растительн</th>
          <th>Минимальная высота</th>
        </tr>
        </thead>
        <tbody>
        {plants.length && plants.map((item, idx) => (
          <tr key={item + idx}>
            <td>{item.pasture.date}</td>
            <td>{item.region ? item.region : ''}</td>
            <td>{item.district ? item.district : ''}</td>
            <td>{item.village ? item.village : ''}</td>
            <td>{item.pasture.user.organization}</td>
            <td>{item.pasture.user.username}</td>
            <td>{item.pastureName}</td>
            <td>{item.plotName}</td>
            <td>{item.plotDescription}</td>
            <td>{item.pointDescription}</td>
            <td>{item.pasture.harvLocation.lat}</td>
            <td>{item.pasture.harvLocation.lon}</td>
            <td/>
            <td>{item.plants}</td>
            <td>{item.plantTypes.map(plantType => plantType.name_ky).join(', ')}</td>
            <td>{item.plantTypes.map(plantType => plantType.name_en).join(', ')}</td>
            <td>{item.trees}</td>
            <td>{item.treeTypes.map(plantType => plantType.name_ky).join(', ')}</td>
            <td>{item.treeTypes.map(plantType => plantType.name_en).join(', ')}</td>
            <td>{item.soilTexture}</td>
            <td>{item.stone}</td>
            <td>{item.soilColor}</td>
            {item.erosionDegree.split(',').map((item, key) => <td key={key}>{item}</td>)}
            <td>{item.cattlePasture}</td>
            {parseDataForStatic(item)}
          </tr>
        ))}
        </tbody>
      </table>
      <Container maxWidth="xl">
        <Toolbar>
          {/*{tableFilter === 'Расстительный покров' ?*/}
          {/*  pastures && pastures.length ? <CustomTable data={pastures}/> : <CircularProgress className={classes.loading}/> :*/}
          {/*  plants && plants.length ? <PlantsTable data={plants}/> : <CircularProgress className={classes.loading}/>*/}
          {/*}*/}

          {pastures && !loading ? <CustomTable data={pastures}/> :
            <CircularProgress className={classes.loading}/>}
        </Toolbar>
      </Container>
    </>
  )
}

export default MainTable;
