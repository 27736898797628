import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, Paper, TableBody, TableCell,Table, TableContainer, TableHead, TableRow} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  table: {
    filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))',
    '& thead th': {
      fontWeight: 700
    }
  },
  tableRow: {
    '&:hover': {
      background: '#F1F6F8'
    }
  }
}));

const CustomTable = ({data}) => {

  const classes = useStyles();

  function zerofill(i) {
    return (i < 10 ? '0' : '') + i;
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >№</TableCell>
            <TableCell align="left">Организация</TableCell>
            <TableCell align="left">Дата замера</TableCell>
            <TableCell align="left">Пользователь</TableCell>
            {/*<TableCell align="left">N</TableCell>*/}
            {/*<TableCell align="left">E</TableCell>*/}
            <TableCell align="left">Подробнее</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item,idx) => (
            <TableRow key={item.id + idx} className={classes.tableRow}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.user.organization}
              </TableCell>
              <TableCell align="left">
                {zerofill(item.date[0])}-{zerofill(item.date[1])}-{item.date[2]}
              </TableCell>
              <TableCell align="left">
                {item.user.username}
              </TableCell>
              {/*<TableCell align="left">{item.harvLocation.lat}</TableCell>*/}
              {/*<TableCell align="left">{item.harvLocation.lon}</TableCell>*/}
              <TableCell align="left">
                <Link to={`/pasture/${item.userId}/${item.plotName}/${item.pastureName}`}>Подробнее</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomTable;
