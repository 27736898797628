import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid, InputBase, makeStyles, MenuItem, Paper, Select, Toolbar, withStyles,Typography, Button} from "@material-ui/core";
import {Bar} from "react-chartjs-2";
import noImg from "../../assets/img/no-img.png";
import calendar from "../../assets/icons/calendar.png";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "30px"
  },
  arrowBack: {
    marginRight: '20px'
  },
  title: {
    fontWeight: 700,
  },
  button: {
    borderRadius: "50px",
    fonsSize: "14px",
    marginLeft: "auto",
    textTransform: "capitalize",
    boxShadow: "none !important",
    fontWeight: "700",
    background: "#1A6D12",
    padding: '8px 16px',
    color: "#fff",
    margin: 10,
    '&:hover': {
      background: "#0f5109",
    }
  },
  paper: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    display: "flex",
    justifyContent: "center",
    borderRadius: '12px'
  },
  optionWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    '& *': {
      margin: 0,
      padding: 0,
    }
  },
  optionTitle: {
    marginRight: '10px'
  },
  image: {
    maxWidth: '100%'
  },
  gridWrap: {
    paddingBottom: '40px',
    borderBottom: '0.5px solid #C4C4C4'
  },
  chartTitle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "15px",
    paddingLeft: '8px',
    margin: '20px 0'
  }
}));

const Input = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    background: 'transparent !important',
    fontWeight: 700
  },
}))(InputBase);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        suggestedMin: 0,
        beginAtZero: true,
        max: 100
      }
    }]
  },
  tooltips: {
    callbacks: {
      title: () => "",
      label: function (tooltipItem, data) {
        try {
          let label = ' ' + data.datasets[tooltipItem.index].label;

          if (label) {
            label += ': ';
          }

          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          label += Number((value)).toFixed(2) + '%';
          return label;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  legend: {
    display: true,
    position: 'bottom',

    labels: {
      fontColor: '#333333',
      padding: 20,
    }
  }
};

const canvasDataCreate = ({ eated,nonEated }) => {
  return {
    data: null,
    datasets: [
      {
        label: "Поедаемые",
        backgroundColor: "#66ACFF",
        data: [eated / (eated + nonEated) * 100]
      },
      {
        label: "Непоедаемые",
        backgroundColor: "#FF6C6C",
        data: [nonEated / (eated + nonEated) * 100]
      }
    ]
  }
}

const YieldTable = ({firebaseJson,setDataForTable}) => {
  const classes = useStyles();
  const [year, setYear] = React.useState('Выберите год');
  const [canvasData,setCanvasData] = useState({});
  const [image, setImage] = useState()

  let [count,setCount] = useState(0);
  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const optionsForSelect = useMemo(() => {
    const years = new Set(
      firebaseJson.map((item) => {
        return item.date
      })
    );
    return [...years].map(item => (
      <MenuItem key={item} value={item}>{item}</MenuItem>
    ))
  }, [firebaseJson]);

  const parseDataForChart = useCallback(data =>{
    const sumDryBiomassEated = [],
    sumDryBiomassNonEated = [],
    sumWetBiomassEated = [],
    sumWetBiomassNonEated = [];

    data.forEach(item => {
      sumDryBiomassEated.push(item.sumDryBiomass.eated);
      sumDryBiomassNonEated.push(item.sumDryBiomass.nonEated);
      sumWetBiomassEated.push(item.sumWetBiomass.eated);
      sumWetBiomassNonEated.push(item.sumWetBiomass.nonEated);
    });

    if(sumDryBiomassEated.length < 1) {
      sumDryBiomassEated.push(0);
    }if(sumDryBiomassNonEated.length < 1) {
      sumDryBiomassNonEated.push(0);
    }if(sumWetBiomassNonEated.length < 1) {
      sumWetBiomassNonEated.push(0);
    }if(sumWetBiomassEated.length < 1) {
      sumWetBiomassEated.push(0);
    }

    const dryData = canvasDataCreate({eated: sumDryBiomassEated.reduce((a, b) => a + b), nonEated: sumDryBiomassNonEated.reduce((a, b) => a + b)});
    const wetData = canvasDataCreate({eated: sumWetBiomassEated.reduce((a, b) => a + b), nonEated: sumWetBiomassNonEated.reduce((a, b) => a + b)});

    canvasData.dry = dryData;
    canvasData.wet = wetData;

    if(setDataForTable) {
      setDataForTable({
        dry: dryData,
        wet: wetData,
      })
    }
    console.log('I was called by a some dog',canvasData)
    setCanvasData({...canvasData});
  },[setDataForTable,canvasData]);

  useEffect(() => {
    if(count < 5) {
      count++;
      setCount(count);
      parseDataForChart(firebaseJson);
    }
  }, [firebaseJson,count,parseDataForChart]);

  useEffect(() => {
    console.log('year has changed')
    const jsonFromFirebase = firebaseJson.filter((item) => {
      if (item.harvestPhoto !== '' && image !== '') {
        setImage(item.harvestPhoto)
      }

      return year === item.date
        // .replace(/[^a-z\d\s]+/gi,'').split(' ').filter(item => +item >= 2020).join('')
    });

    if(count < 2) {
      count++;
      setCount(count);
      parseDataForChart([...jsonFromFirebase]);
    }

  },[year,firebaseJson,count,parseDataForChart]);

  return (
    <>
      <Toolbar>
        <div className={classes.optionWrap}>
          <img src={calendar} style={{marginRight: '20px'}} alt={'calendar icon'}/>
          <p className={classes.optionTitle}>Период</p>
          <Select
            value={year}
            className={classes.margin}
            onChange={handleChange}
            input={<Input/>}
          >
            {optionsForSelect}
          </Select>
        </div>
      </Toolbar>
      <Toolbar className={classes.gridWrap}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Paper className={classes.paper} style={{height: '100%',minHeight: "300px",padding: '8px',}}>
              <div style={{width: "50%"}}>
                <Typography className={classes.chartTitle} variant="h6">Влажное, кг/га</Typography>
                <div style={{height: '80%'}}>
                  <Bar data={canvasData.wet} options={options}/>
                </div>
              </div>
              <div style={{width: '50%'}}>
                <Typography className={classes.chartTitle} variant="h6">Сухое, кг/га</Typography>
                <div style={{height: '80%'}}>
                  <Bar data={canvasData.dry} options={options}/>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item md={6}>

            <Paper className={classes.paper} style={{height: '100%',padding: '8px',minHeight: "300px", display: 'flex', alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
              {image ? (
                  <>
                    <img onError={e => {
                      e.target.src = noImg
                    }} style={{ maxWidth: '100%' }} src={"data:image/png; base64, " + image} alt="Vegetable" />

                    <a href={`data:image/png; base64, ${image}`} download style={{ textDecoration: 'none' }}>
                      <Button className={classes.button}>
                        Скачать
                      </Button>
                    </a>
                  </>
                ) : (
                  <>
                    <img src={noImg} alt="No img"/>
                    <p className={classes.noImgText}>Фотография отсутствует</p>
                  </>
                )}
            </Paper>
          </Grid>
        </Grid>
      </Toolbar>
    </>
  )
}

export default YieldTable;
