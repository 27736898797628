import React from 'react';
import {makeStyles, Paper, TableBody, TableCell,Table, TableContainer, TableHead, TableRow, Button} from "@material-ui/core";
import firebase from '../../firebase';

const useStyles = makeStyles(() => ({
  table: {
    filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))',
    '& thead th': {
      fontWeight: 700
    }
  },
  tableRow: {
    '&:hover': {
      background: '#F1F6F8'
    }
  },
  button: {
    background: '#f33446',
    textTransform: 'initial',
    color: '#fff',
    borderRadius: 30,
    fontWeight: 600,

    '&:hover': {
      background: '#f33446',
    }
  }
}));

const PostureTable = ({data: propsData}) => {
  const classes = useStyles();
  const [data, setData] = React.useState([...propsData])

  const remove = id => () => {
    if (window.confirm('Удалить?')) {
      firebase.database().ref(`/plants/`+id).remove()
        .then(() => {
          setData(data.filter(item => item.mainId !== id))
        })
    }
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell align="left">Дата измерения</TableCell>
            <TableCell align="left">Название пастбища</TableCell>
            <TableCell align="left">Название участка</TableCell>
            <TableCell align="left">Координаты участка</TableCell>
            <TableCell align="left">Пользователь</TableCell>
            <TableCell align="left">Организация</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item,idx) => (
            <TableRow
              key={item.id + idx}
              className={classes.tableRow}>
              <TableCell>
                {idx + 1}
              </TableCell>
              <TableCell align="left">
                {item.date}
              </TableCell>
              <TableCell align="left">
                {item.pastureName}
              </TableCell>
              <TableCell align="left">
                {item.plotName}
              </TableCell>
              <TableCell align="left">
                <a rel="noopener noreferrer" target="_blank" href={`http://maps.apple.com/maps?q=${item.harvestLocation.split(' ').join(',')}`}>
                  {item.harvestLocation}
                </a>
              </TableCell>
              <TableCell align="left">
                {item.user.username}
              </TableCell>
              <TableCell align="left">
                {item.user.organization}
              </TableCell>
              <TableCell align="left">
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  className={classes.button}
                  onClick={remove(item.mainId)}
                >
                  Удалить
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PostureTable;
